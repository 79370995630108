import { XIcon } from "@heroicons/react/solid";
import { Tooltip } from "@material-ui/core";
import {
  mdiAccountClockOutline,
  mdiAttachment,
  mdiBookCheck,
  mdiClose,
  mdiFileClock,
  mdiMicrosoftExcel,
  mdiSwapVertical,
  mdiTextBoxCheckOutline,
  mdiTextBoxMultiple,
  mdiTools,
} from "@mdi/js";
import Icon from "@mdi/react";
import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { Complaintmodel } from "../../../../service/models/complaint_model";
import { getComplaints } from "../../../../service/repos/support/complaint_repo";
import {
  complaintStatus,
  complaint_by,
  complaint_related_to,
  PriorityType,
} from "../../../../utils/enums";
import { formToObject } from "../../../../utils/formDataToJson";
import { stateData } from "../../../../utils/states_data";
import { urls } from "../../../../utils/urls";

import LoadingWidget from "../../../components/loading_spinner";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../new_components/common/drawer_modal";
import FilterDropDown from "../../../new_components/common/filter_drop_down";
import DoneDocIcon from "../../../new_components/common/icons/done_doc_icon";
import MultichatIcon from "../../../new_components/common/icons/multichat_icon";
import PendingDocIcon from "../../../new_components/common/icons/pendng_doc_icon";
import ModalDialog from "../../../new_components/common/modal";
import Pagination from "../../../new_components/common/pagination";
import EmployeeSearchWidget from "../../../new_components/emp_search_widget";
import SearchFieldWidget from "../../../new_components/search_field";
import AttachmentWidget from "../detail/components/attaments_widget";
import CommentWidget from "./components/comment_widget";
import ExportComplaints from "./components/export_widget";
import HistoryWidget from "./components/history_widget";
export interface ComplaintFilterModle {
  search?: string;
  page: number;
  count: number;
  all?: any;
  filterData?: {
    [key: string]: any;
    fromDate?: Date;
    toDate?: Date;
    complaintBy?: string[];
    complainRelatedTo?: string[];
    responsiblePerson?: string[];
    emp_name?: string;
    status?: string[];
    state?: string[];
    pre_post?: string[];
    priority?: string[];
    active?: boolean;
    sort_by?: string;
    sort_order?: string;
  };
}
const defaultFilter: ComplaintFilterModle = {
  page: 1,
  count: 100,
  filterData: { active: true, sort_by: "complaint_date", sort_order: "desc" },
};
interface ComplaintListingPageProps {}

const ComplaintListingPage: FunctionComponent<
  ComplaintListingPageProps
> = () => {
  const { showToast } = useToast();
  const tempFilter = localStorage.getItem("com_filter");
  // const [emp, setEmp] = useState<EmployeeModel>();
  const [filter, setFilter] = useState<ComplaintFilterModle>(
    tempFilter ? JSON.parse(tempFilter) : defaultFilter
  );
  const [showPopUp, setShowPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [analytic, setAnalytic] =
    useState<{ status: string; count: number }[]>();
  const [averageTime, setAverageTime] = useState<{
    cur_month: {
      count: number;
      days: number;
    };
    last_month: {
      count: number;
      days: number;
    };
    all_days: {
      count: number;
      days: number;
    };
  }>();
  const [complaints, setComplaints] = useState<Complaintmodel[]>([]);
  const [showHistory, setShowHistory] = useState("");
  const [showComment, setShowComment] = useState("");

  const [showAttachments, setShowAttachments] = useState<Complaintmodel>();
  const [showExport, setShowExport] = useState(false);
  const [overDue, setOverDue] = useState({ all: 0, today: 0 });
  const fetchComplaints = async () => {
    setLoading(true);

    const res = await getComplaints(filter);
    console.log(res);

    if (res.success) {
      setComplaints(res.data.complaints);
      if (res.data.metadata[0]) setTotal(res.data.metadata[0].total);

      setAnalytic(res.analytic);
      if (res.average_time) setAverageTime(res.average_time);
      if (res.over_due) setOverDue(res.over_due);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    localStorage.setItem("com_filter", JSON.stringify(filter));

    const cf = localStorage.getItem("cf");
    const now = new Date();

    if (
      !cf ||
      moment(parseInt(cf)).isBefore(
        new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
      )
    )
      setShowPopUp(true);
    fetchComplaints();
  }, [filter]);

  // useEffect(() => {
  //   if (emp) {
  //     setFilter((o) => ({
  //       ...o,
  //       filterData: {
  //         ...o.filterData,
  //         responsiblePerson: [
  //           ...(o.filterData!.responsiblePerson
  //             ? o.filterData!.responsiblePerson
  //             : []),
  //           emp.uid!,
  //         ],
  //       },
  //     }));
  //   } else {
  //     setFilter({
  //       ...filter,
  //       filterData: {
  //         ...filter.filterData,
  //         responsiblePerson: undefined,
  //       },
  //     });
  //   }
  // }, [emp]);
  // const exportData = async () => {
  //   setLoading(true);
  //   const res = await getComplaints({ ...filter, all: true });
  //   if (!res.success) {
  //     setLoading(false);
  //     return;
  //   }
  //   const options = {
  //     fieldSeparator: ",",
  //     quoteStrings: '"',
  //     decimalSeparator: ".",
  //     showLabels: true,
  //     showTitle: true,
  //     title: "Complainants",
  //     useTextFile: false,
  //     useBom: true,
  //     useKeysAsHeaders: true,
  //     // headers: [
  //     //   "Name",
  //     //   "Dept",
  //     //   "Earned leave av.",
  //     //   "Earned leave taken",
  //     //   "Current month taken",
  //     //   "Medical leave av.",
  //     //   "Medical leave taken",
  //     //   "Current month taken",
  //     //   "Unpaid leave",
  //     // ],
  //   };

  //   const csvExporter = new ExportToCsv(options);

  //   csvExporter.generateCsv(res.data);
  //   setLoading(false);
  // };
  return (
    <div className="p-5">
      {showExport && (
        <ModalDialog
          show={showExport}
          title="Export"
          onClose={() => setShowExport(false)}
        >
          <ExportComplaints filter={filter} />
        </ModalDialog>
      )}
      {showAttachments && (
        <ModalDialog
          show={showAttachments !== undefined}
          title="Attachments"
          onClose={() => setShowAttachments(undefined)}
        >
          <AttachmentWidget complaint_id={showAttachments.id} />
        </ModalDialog>
      )}
      {showPopUp && (
        <ModalDialog
          title=""
          show={showPopUp}
          onClose={() => {
            localStorage.setItem("cf", Date.now().toString());
            setShowPopUp(false);
          }}
        >
          <div
            className="flex justify-center p-4"
            style={{ height: "75vh", width: "auto", minWidth: "70vw" }}
          >
            <img
              src="/images/complaint_flow_chart.png"
              alt=""
              style={{ height: "75vh", width: "auto" }}
            />
          </div>
        </ModalDialog>
      )}
      {showHistory !== "" && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          show={showHistory !== ""}
          onClose={() => setShowHistory("")}
          title="History"
        >
          <HistoryWidget complaint_id={showHistory} />
        </DrawerModal>
      )}
      {showComment !== "" && (
        <DrawerModal
          onClose={() => setShowComment("")}
          title="Comments"
          show={showComment !== ""}
          location={DrawerModalLoaction.right}
        >
          <CommentWidget complaint_id={showComment} />
        </DrawerModal>
      )}
      <div className="">
        <div className="grid grid-col-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-5 gap-4 py-2 ">
          <div className="flex items-center gap-2 border p-4 rounded-md  justify-between bg-white">
            <div className="">
              <div className="text-sm text-gray-500">All tickets</div>
              <div className="font-semibold text-2xl">{total ?? 0}</div>
            </div>
            <div className="h-8 w-8  rounded-full flex justify-center items-center bg-blue-200">
              {" "}
              <Icon
                path={mdiTextBoxMultiple}
                className="h-4 w-4 text-white"
              />{" "}
            </div>
          </div>
          <div className="flex items-center gap-2 border p-4 rounded-md  justify-between bg-white">
            <div className="">
              <div className="text-sm text-gray-500">Pending tickets</div>
              <div className="font-semibold text-2xl">
                {analytic?.find(
                  (d) => d.status.toLowerCase() === "Pending".toLowerCase()
                )?.count ?? 0}
              </div>
            </div>
            <div className="h-8 w-8  rounded-full flex justify-center items-center bg-yellow-600">
              <Icon path={mdiFileClock} className="h-4 w-4 text-white" />
            </div>
          </div>
          <div className="flex items-center gap-2 border p-2 rounded-md  justify-between bg-white">
            <div className="">
              <div className="text-sm text-gray-500">
                Under progress tickets
              </div>
              <div className="font-semibold text-2xl">
                {analytic?.find(
                  (d) =>
                    d.status.toLowerCase() === "Under progress".toLowerCase()
                )?.count ?? 0}
              </div>
            </div>
            <div className="p-2 rounded-full flex justify-center items-center bg-yellow-400">
              <Icon path={mdiTools} className="h-4 w-4 text-white" />
            </div>
          </div>
          <div className="flex items-center gap-2 border p-2 rounded-md  justify-between bg-white">
            <div className="">
              <div className="text-sm text-gray-500">
                Noted for future correction
              </div>
              <div className="font-semibold text-2xl">
                {analytic?.find(
                  (d) =>
                    d.status.toLowerCase() === "Noted for future".toLowerCase()
                )?.count ?? 0}
              </div>
            </div>
            <div className="p-2 rounded-full flex justify-center items-center bg-green-200">
              <Icon path={mdiBookCheck} className="h-4 w-4 text-white" />{" "}
            </div>
          </div>
          <div className="flex items-center gap-2 border p-2 rounded-md  justify-between bg-white">
            <div className="">
              <div className="text-sm text-gray-500">Solved tickets</div>
              <div className="font-semibold text-2xl">
                {analytic?.find(
                  (d) => d.status.toLowerCase() === "Solved".toLowerCase()
                )?.count ?? 0}
              </div>
            </div>
            <div className="p-2 rounded-full flex justify-center items-center bg-teal">
              <Icon
                path={mdiTextBoxCheckOutline}
                className="h-4 w-4 text-white"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white p-3 rounded-md">
        <div className="flex justify-between">
          <div className=" flex gap-8">
            {averageTime && (
              <div className="mt-2">
                <h3 className="text-sm text-gray-500 border-b border-gray-300">
                  Average turnaround time
                </h3>
                <div className="flex text-sm gap-5 mt-2">
                  <p className="text-gray-500">
                    Current month :{" "}
                    <b className="text-black">
                      {(averageTime?.cur_month.count ?? 0) > 0
                        ? Math.ceil(
                            (averageTime?.cur_month.days ?? 0) /
                              (averageTime?.cur_month.count ?? 0)
                          )
                        : 0}{" "}
                      days
                    </b>
                  </p>
                  <p className="text-gray-500">
                    Last month :{" "}
                    <b className="text-black">
                      {(averageTime?.last_month.count ?? 0) > 0
                        ? Math.ceil(
                            (averageTime?.last_month.days ?? 0) /
                              (averageTime?.last_month.count ?? 0)
                          )
                        : 0}{" "}
                      days
                    </b>
                  </p>
                  <p className="text-gray-500">
                    All :{" "}
                    <b className="text-black">
                      {(averageTime?.all_days.count ?? 0) > 0
                        ? Math.ceil(
                            (averageTime?.all_days.days ?? 0) /
                              (averageTime?.all_days.count ?? 0)
                          )
                        : 0}{" "}
                      days
                    </b>
                  </p>
                </div>
              </div>
            )}

            <div className="mt-2">
              <h3 className="text-sm text-gray-500 border-b border-gray-300">
                Overdue
              </h3>
              <div className="flex text-sm gap-5 mt-2">
                <p className="text-gray-500">
                  All: <b className="text-black">{overDue.all}</b>
                </p>
                <p className="text-gray-500">
                  Today : <b className="text-black">{overDue.today}</b>
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 items-end">
            <Tooltip title="Ticketer name, mobile,complaint ID, Responsible person">
              <div className="">
                <SearchFieldWidget
                  placeholder="Search..."
                  onClear={() => {
                    setFilter((old) => ({ ...old, search: "", page: 1 }));
                  }}
                  onSubmit={(q) => {
                    setFilter((old) => ({ ...old, search: q, page: 1 }));
                  }}
                />
              </div>
            </Tooltip>
            <button
              className="border rounded px-2 py-1 flex items-center gap-2"
              onClick={() => setShowExport(true)}
            >
              <Icon path={mdiMicrosoftExcel} className="h-4 w-4  " />
              Export
            </button>
          </div>
        </div>
        <div className="flex justify-between items-end text-sm">
          <h3 className="mt-2">Filter</h3>
          <button
            onClick={() => {
              setFilter(defaultFilter);
            }}
          >
            Clear
          </button>
        </div>
        <div className="rounded  p-1 grid grid-cols-1 md:grid-cols-8  gap-3   bg-white   ">
          <div className="">
            <FilterDropDown
              placeholder="Ticket by"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    complaintBy: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    complaintBy: filter.filterData?.complaintBy?.filter(
                      (f) => f !== type
                    ),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    complaintBy: [...(old.filterData?.complaintBy ?? []), type],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.complaintBy ?? []}
              options={Object.values(complaint_by).map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div>
          <div className="">
            <FilterDropDown
              placeholder="Ticket for"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    complainRelatedTo: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    complainRelatedTo:
                      filter.filterData?.complainRelatedTo?.filter(
                        (f) => f !== type
                      ),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    complainRelatedTo: [
                      ...(old.filterData?.complainRelatedTo ?? []),
                      type,
                    ],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.complainRelatedTo ?? []}
              options={Object.values(complaint_related_to).map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div>{" "}
          <div className="  ">
            <FilterDropDown
              placeholder=" Pre / Post Delivery"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    pre_post: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    pre_post: filter.filterData?.pre_post?.filter(
                      (f) => f !== type
                    ),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    pre_post: [...(old.filterData?.pre_post ?? []), type],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.pre_post ?? []}
              options={["Pre Delivery", "Post Delivery"].map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div>
          <div className="  ">
            <FilterDropDown
              placeholder="Priority"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    priority: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    priority: filter.filterData?.priority?.filter(
                      (f) => f !== type
                    ),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    priority: [...(old.filterData?.priority ?? []), type],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.priority ?? []}
              options={Object.values(PriorityType).map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div>
          <div className=" ">
            <FilterDropDown
              placeholder="Status"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    status: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    status: filter.filterData?.status?.filter(
                      (f) => f !== type
                    ),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    status: [...(old.filterData?.status ?? []), type],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.status ?? []}
              options={Object.values(complaintStatus).map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div>
          <div className="    ">
            <div className="flex gap-1 items-center">
              <select
                className="border rounded text-sm w-full h-8 focus:outline-none cursor-pointer"
                name="active"
                id="active"
                value={
                  filter.filterData?.active !== undefined
                    ? filter.filterData!.active
                      ? "true"
                      : "false"
                    : ""
                }
                onChange={(e) => {
                  if (e.target.value === "") {
                    delete filter.filterData?.active;
                    setFilter((o) => ({
                      ...o,
                    }));
                    return;
                  }
                  setFilter((o) => ({
                    ...o,
                    filterData: {
                      ...o.filterData,
                      active: e.target.value === "true",
                    },
                  }));
                }}
              >
                <option value="">All</option>
                <option value="true">Active</option>
                <option value="false">Closed</option>
              </select>
            </div>
          </div>{" "}
          <div className=" ">
            <FilterDropDown
              placeholder="State"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    state: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    state: filter.filterData?.state?.filter((f) => f !== type),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    state: [...(old.filterData?.state ?? []), type],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.state ?? []}
              options={stateData.map((state) => ({
                label: state.name,
                value: state.name,
              }))}
            />
          </div>
          <div className=" z-20 ">
            {/* <label htmlFor="" className="text-sm">
            Responsible person
          </label> */}
            <div className="flex gap-1 items-center w-full">
              {!loading && (
                <EmployeeSearchWidget
                  placeholder="Responsible person"
                  emp={
                    filter.filterData?.responsiblePerson
                      ? {
                          uid: filter.filterData
                            ? filter.filterData?.responsiblePerson[0]
                            : "",
                          name: filter.filterData.emp_name ?? "",
                        }
                      : undefined
                  }
                  withBtn={false}
                  onSelect={(emp) => {
                    setFilter((old) => ({
                      ...old,
                      filterData: {
                        ...old.filterData,
                        responsiblePerson: [emp.uid!],
                        emp_name: emp.first_name + " " + emp.last_name,
                      },
                    }));
                  }}
                />
              )}
              {filter.filterData && filter.filterData.emp_name && (
                <button
                  className="text-xs"
                  onClick={() => {
                    setLoading(true);
                    setFilter((old) => ({
                      ...old,
                      filterData: {
                        ...old.filterData,
                        responsiblePerson: undefined,
                        emp_name: undefined,
                      },
                    }));
                    setLoading(false);
                  }}
                >
                  {/* <Icon path={mdiClose} className="h-4 w-4"></Icon> */}
                  Clear
                </button>
              )}
            </div>
          </div>{" "}
        </div>
      </div>
      <div className="mt-3 p-2 bg-white">
        <Pagination
          itemCount={complaints.length}
          page={filter.page}
          total={total}
          count={filter.count}
          onChange={(page) => {
            setFilter((old) => ({ ...old, page }));
          }}
        />
      </div>
      <div className=" flex justify-center" style={{ height: "90vh" }}>
        <div
          id="scroll_listener"
          className=" overflow-auto h-full border rounded-lg bg-white"
        >
          <table className=" divide-y divide-gray-200 relative text-xs">
            <thead className="bg-gray-200     py-2 sticky  -top-1 z-10   whitespace-nowrap  ">
              <tr className=" py-2 bg-gray-200  text-gray-600">
                <th className="p-2 lg:sticky lg:left-0  bg-gray-200 "></th>
                <th
                  onClick={() => {
                    const sort_order =
                      filter.filterData?.sort_order === "desc" ? "asc" : "desc";
                    setFilter((o) => ({
                      ...o,
                      filterData: { ...o.filterData, sort_order },
                    }));
                  }}
                  className="p-2 border lg:sticky lg:left-80p  bg-gray-200 cursor-pointer"
                  // style={{ left: "75px" }}
                >
                  <Tooltip
                    title={
                      filter.filterData?.sort_order === "desc"
                        ? "Descending"
                        : "Ascending"
                    }
                  >
                    <div className="flex gap-2 justify-center">
                      Date
                      <span>
                        <Icon path={mdiSwapVertical} className="h-4 w-4  " />
                      </span>
                    </div>
                  </Tooltip>
                </th>
                <th className=" border  ">Due Date</th>
                <th className=" border  ">Ago</th>
                <th className=" border  ">Complaint ID</th>
                <th className=" border    ">Device ID</th>
                <th className=" border ">Responsible person</th>
                <th className=" border  ">Priority</th>{" "}
                <th
                  className=" border  lg:sticky  lg:left-170p bg-gray-200"
                  // style={{ left: "160px" }}
                >
                  Status
                </th>
                <th className=" border  ">Visibility</th>
                <th
                  className=" border    bg-gray-200"
                  // style={{ left: "270px" }}
                >
                  Ticket by
                </th>
                <th
                  className=" border lg:sticky lg:left-280p  bg-gray-200"
                  // style={{ left: "270px" }}
                >
                  Created by
                </th>
                <th
                  className=" border lg:sticky lg:left-390p bg-gray-200 "
                  // style={{ left: "390px" }}
                >
                  Ticket for
                </th>
                <th className=" border  ">Pre/post delivery</th>
                <th className=" border ">Responsible department</th>
                <th className=" border  ">Ticketer name</th>
                <th className=" border  ">Ticketer mobile</th>
                <th className=" border    whitespace-nowrap w-10 overflow-hidden">
                  Ticketer address
                </th>
                <th className=" border ">Next action date</th>
                <th className=" border ">Subject</th>
                <th className=" border ">Description</th>
              </tr>
            </thead>
            <tbody>
              {complaints.map((complaint, i) => (
                <tr
                  key={i}
                  className={`border py-2 text-xs hover:bg-gray-200 sticky-row  ${
                    !complaint.active ? "text-gray-400" : ""
                  }`}
                >
                  <td className="lg:sticky lg:left-0 sticky-field">
                    <div className="flex gap-1 px-1">
                      <Tooltip title="Complaint ID">
                        <svg
                          onClick={() => {
                            navigator.clipboard.writeText(complaint.id);

                            showToast({
                              type: ToastType.error,
                              text: "Copied complaint ID to clip board!",
                            });
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 cursor-pointer hover:text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                          />
                        </svg>
                      </Tooltip>
                      <Tooltip title="Notes">
                        <svg
                          onClick={() => setShowComment(complaint.id)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 hover:text-red-600 cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                          />
                        </svg>
                      </Tooltip>
                      <Tooltip title="history">
                        <svg
                          onClick={() => setShowHistory(complaint.id)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4  cursor-pointer hover:text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </Tooltip>
                      <Tooltip title="Attachment">
                        <div
                          onClick={() => setShowAttachments(complaint)}
                          className="cursor-pointer"
                        >
                          <Icon path={mdiAttachment} className="w-4 h-4"></Icon>
                        </div>
                      </Tooltip>
                    </div>
                  </td>
                  <td
                    className={`px-3 py-1 border whitespace-nowrap lg:sticky lg:left-80p sticky-field `}
                  >
                    {moment(complaint.complaint_date)
                      .utcOffset(330)
                      .format("DD MMM YYYY")}
                  </td>
                  <td
                    className={`px-3 py-1 border whitespace-nowrap lg:sticky lg:left-80p sticky-field `}
                  >
                    {moment(complaint.next_action_date)
                      .utcOffset(330)
                      .format("DD MMM YYYY")}
                  </td>
                  <td>
                    <p className="text-center">
                      {moment().diff(moment(complaint.complaint_date), "day")}
                    </p>
                  </td>
                  <td className="px-2 py-1 border">
                    <Tooltip title={complaint.description}>
                      <NavLink
                        // target="_blank"
                        to={urls.complaint_detail + "?id=" + complaint.id}
                      >
                        <div className="mx-1 cursor-pointer flex  text-blue-400 ">
                          {complaint.id}
                        </div>
                      </NavLink>
                    </Tooltip>
                  </td>
                  <td className="px-2 py-1 border ">
                    <Tooltip title={complaint.device_id ?? ""}>
                      <div className="whitespace-nowrap w-20 overflow-hidden overflow-ellipsis ">
                        {complaint.device_id ?? "--"}
                      </div>
                    </Tooltip>
                  </td>{" "}
                  <td className="px-2 py-1 border">
                    {complaint.res_person_name ?? "--"}
                  </td>
                  <td
                    align="center"
                    className={`px-2  border  font-medium whitespace-nowrap ${
                      complaint.priority === PriorityType.high
                        ? "text-yellow-700"
                        : complaint.priority === PriorityType.medium
                        ? "text-yellow-500"
                        : "text-yellow-200"
                    }`}
                  >
                    <div className=" ">{complaint.priority}</div>
                  </td>
                  <td
                    align="center"
                    className={`px-3  border lg:sticky lg:left-170p sticky-field font-medium whitespace-nowrap ${
                      complaint.status.toLowerCase() === "pending"
                        ? "text-yellow-700"
                        : complaint.status.toLowerCase() === "solved"
                        ? "text-green-200"
                        : "text-yellow-500"
                    }`}
                  >
                    <div className="w-24 ">{complaint.status}</div>
                  </td>
                  <td
                    align="center"
                    className={`px-2  border  font-medium whitespace-nowrap  `}
                  >
                    <div className=" ">
                      {complaint.active ? "Active" : "Closed"}
                    </div>
                  </td>
                  <td className="px-2 py-1 border  ">
                    <NavLink
                      // target="_blank"
                      to={urls.complaint_detail + "?id=" + complaint.id}
                    >
                      <div className="mx-1 cursor-pointer flex  text-blue-400    whitespace-nowrap w-24 overflow-hidden overflow-ellipsis ">
                        {complaint.complaint_by}
                      </div>
                    </NavLink>
                  </td>
                  <td className="px-2 py-1 border lg:sticky lg:left-280p sticky-field">
                    <NavLink
                      // target="_blank"
                      to={urls.complaint_detail + "?id=" + complaint.id}
                    >
                      <Tooltip title={complaint.created_by_name ?? ""}>
                        <div className="mx-1 cursor-pointer flex  text-blue-400    whitespace-nowrap w-24 overflow-hidden overflow-ellipsis ">
                          {complaint.created_by_name}
                        </div>
                      </Tooltip>
                    </NavLink>
                  </td>
                  <td className="px-2 py-1 border lg:sticky lg:left-390p sticky-field">
                    {complaint.related_to}
                  </td>
                  <td className="px-2 py-1 border">
                    {complaint.pre_post_delivery ?? "--"}
                  </td>{" "}
                  <td className="px-2 py-1 border">
                    {complaint.res_dep ?? "--"}
                  </td>
                  <td className="px-2 py-1 border">
                    <Tooltip title={complaint.complainant_name}>
                      <p className="whitespace-nowrap w-40 overflow-hidden overflow-ellipsis">
                        {complaint.complainant_name ?? "--"}
                      </p>
                    </Tooltip>
                  </td>
                  <td className="px-2 py-1 border">
                    {complaint.complainant_mobile ?? "--"}
                  </td>
                  <td className="px-2 py-1 border ">
                    <Tooltip title={complaint.address}>
                      <p className="whitespace-nowrap w-40 overflow-hidden overflow-ellipsis">
                        {complaint.city ?? complaint.address}
                      </p>
                    </Tooltip>
                  </td>
                  <td className="px-2 py-1 border">
                    {complaint.next_action_date
                      ? moment(complaint.next_action_date).format("DD MMM YYYY")
                      : "--"}
                  </td>
                  <td className="px-2 py-1 border whitespace-nowrap max-w-sm overflow-hidden">
                    {complaint.subject}
                  </td>
                  <td className="px-2 py-1 border whitespace-nowrap max-w-md overflow-hidden">
                    <Tooltip title={complaint.description}>
                      <p>{complaint.description}</p>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pt-2 bg-white">
        <Pagination
          itemCount={complaints.length}
          page={filter.page}
          total={total}
          count={filter.count}
          onChange={(page) => {
            setFilter((old) => ({ ...old, page }));
          }}
        />
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ComplaintListingPage;
