import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import access_routes from "./access_routes";
import auth_routes from "./auth_routes";
import complaint_routes from "./complaint_routes";
import landing_page_routes from "./landing_route";

export interface RouteParam {
  fullScreen: boolean;
  exact?: boolean;
  path: string;
  component?:
    | React.ComponentType<any>
    | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    | undefined;
  main_module?: string;
  module?: string;
  action?: string | string[];
  operator?: "or" | "and";
  isPrivate: boolean;
  routes?: RouteParam[];
}

const main_routes: RouteParam[] = [
  ...auth_routes,
  ...landing_page_routes,
  ...access_routes,
  ...complaint_routes,
];

export default main_routes;
