import { FunctionComponent, useEffect, useState } from "react";

import ReactWordcloud from "react-wordcloud";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { GetComplaintWordCount } from "../../../../service/repos/support/complaint_repo";
import LoadingWidget from "../../../components/loading_spinner";

interface WordCloudPageProps {}

const WordCloudPage: FunctionComponent<WordCloudPageProps> = () => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [words, setWords] = useState<{ text: string; value: number }[]>([]);
  const getWords = async () => {
    setLoading(true);
    const res = await GetComplaintWordCount();

    if (res.success) {
      setWords([...res.data]);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    getWords();
  }, []);
  return (
    <div className="h-screen">
      <h1 className="text-2xl font-semibold text-center my-5">
        Tickets word cloud
      </h1>
      <div className="flex justify-center items-center h-4/5 ">
        <div className=" rounded-md h-full w-full">
          <ReactWordcloud
            words={words}
            options={{
              tooltipOptions: {
                animateFill: true,
                animation: "fade",
              },
              fontSizes: [14, 100],
              spiral: "archimedean",
              rotations: 2,
              rotationAngles: [0, 0],
            }}
          />
        </div>
      </div>
      <div className="flex justify-center my-5">
        <button
          onClick={() => getWords()}
          className="rounded-3xl  text-center border px-3 py-2 hover:bg-black hover:text-white"
        >
          Refresh
        </button>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default WordCloudPage;
